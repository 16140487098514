<template>
  <div class="search">
    <div class="log_page_class">
      <template>
        <el-form
          :inline="true"
          :model="queryForm"
          ref="queryForm"
          class="demo-form-inline"
        >
          <el-row :gutter="24">
            <el-col :span="8" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="应付帐编号:" prop="thirdParty">
                  <el-input v-model="queryForm.thirdParty" placeholder="请输入查询"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="8" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="付款方:" prop="initiatorName">
                  <el-input v-model="queryForm.initiatorName" placeholder="请输入查询"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="8" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="收款方:" prop="acceptorName">
                  <el-input v-model="queryForm.acceptorName" placeholder="请输入查询"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
          <el-col :span="8" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="支付状态:" prop="status">
                  <el-select v-model="queryForm.status" placeholder="请选择">
                    <el-option value="" label="全部" ></el-option>
                    <el-option
                      v-for="item in stateArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.datavalue">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
          <el-col :span="8" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="应付时间:" prop="date">
                   <el-date-picker
                      v-model="queryForm.date"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      >
                    </el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="8" align="left">
              <div>
                <el-form-item class="buttonbox">
                  <el-button
                    type="primary"
                    @click="query"
                    icon="el-icon-search"
                    >查询</el-button
                  >
                  <el-button
                    type="primary"
                    @click="reset"
                    icon="el-icon-refresh-right"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </div>
    <div style="text-align: initial; margin-bottom: 10px" class="buttonbox">
      <el-button type="primary" icon="el-icon-download"
      v-if="funArr.indexOf('导出') !== -1"
       @click="exportTable">导出</el-button>
      <el-button type="primary" icon="el-icon-s-data" @click="openBatchPayDialog" 
      v-if="(funArr.indexOf('批量线上支付') !== -1) || 
      funArr.indexOf('批量线下支付') !== -1">批量支付</el-button>
    </div>
    <el-table
      id="table"
      :data="list"
      v-loading="loading"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      @selection-change="handleSelectionChange"
      border
    >
    <el-table-column
      fixed
      type="selection"
      width="55">
    </el-table-column>
      <!-- <el-table-column
        type="index"
        label="序号"
        width="50"
        show-overflow-tooltip
        align="center"
      /> -->
      <el-table-column
        prop="thirdParty"
        label="应付帐编号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="initiatorName"
        label="付款方"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="acceptorName"
        label="收款方"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="付款金额"
        show-overflow-tooltip
        align="center"
      >
      <template slot-scope="scope">
        {{ centChangeDollar(scope.row.amount)}}
      </template>
      </el-table-column>
      <el-table-column
        prop="paymentTime"
        label="付款时间"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="statementBillIdThirdParty"
        label="所属对账单"
        show-overflow-tooltip
        align="center"
      />
       <el-table-column
        prop="status"
        label="付款状态"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span>{{stateArr[scope.row.status].name}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="paidAmount"
        label="已付金额"
        show-overflow-tooltip
        align="center"
      >
      <template slot-scope="scope">
        {{ centChangeDollar(scope.row.paidAmount)}}
      </template>
      </el-table-column>
        <el-table-column
        prop="lastPaidTime"
        label="最后支付时间"
        show-overflow-tooltip
        align="center"
      />
       
      <el-table-column label="操作" width="210" align="center" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              size="medium"
              v-if="funArr.indexOf('详情') !== -1"
              @click="particulars(scope.row)"
              >详情</el-link
            >
            <el-link
              type="primary"
              icon="el-icon-fetchByIdSysUserList"
              :underline="false"
              size="medium"
              @click="openBillDialog(scope.row)"
              >账单确权</el-link
            >
            <!-- <el-link
              :type="scope.row.isAuthenticRight?'primary':'info'"
              icon="el-icon-fetchByIdSysUserList"
              :underline="false"
              size="medium"
              v-if="funArr.indexOf('额度确权') !== -1"
              :disabled="!scope.row.isAuthenticRight"
              @click="authenticRight(scope.row)"
              >额度确权</el-link
            > -->
            <span>
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('线上支付') !== -1"
                @click="billPayable(scope.row)"
                >支付</el-link
              >
            </span>
            <span>
              <el-link
                icon="el-icon-fetchByIdSysUserList"
                :type="scope.row.isOfflinePay?'primary':'info'"
                :disabled="!scope.row.isOfflinePay"
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('线下支付') !== -1"
                @click="offlineBillPayable(scope.row)"
                >线下支付</el-link
              >
            </span>
          </template>
        </el-table-column>
    </el-table>
    <div>
      <template>
        <el-pagination
          class="sx"
          background
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryForm.pageSize"
          :current-page="queryForm.pageNo"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
    </div>
    <div>
      <el-dialog
        @open="openDialog"
        class="dialogBox"
        ref="batchPay"
        :title="'批量支付:'+selectedRow.length+'张'"
        :close-on-click-modal="false"
        :visible.sync="batchPayDialog"
        :destroy-on-close="true">
        <div><batchPay ref="batchPay" :list="selectedRow" :mobile="userInfo.mobile" /></div>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="submitBatchPay(1)" v-if="funArr.indexOf('批量线下支付') !== -1" :disabled="isBatchOfflinePay">已线下支付</el-button>
          <el-button @click="batchPayDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitBatchPay(0)" v-if="funArr.indexOf('批量线上支付') !== -1" >确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        @open="openDialog"
        class="dialogBox"
        :title="billPayableDialogName"
        :close-on-click-modal="false"
        :visible.sync="billPayableDialog"
        :destroy-on-close="true">
        <div><payForm ref="pay" :mobile="userInfo.mobile" :paymentAmount="paymentAmount"  
        :noPaymentAmount="noPaymentAmount" :currentPaymentAmount="currentPaymentAmount" /></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="billPayableDialog = false">取 消</el-button>
          <el-button v-if="billPayableDialogName == payName" type="primary" @click="submitPay(0)">确 定</el-button>
          <el-button v-else type="success" @click="submitPay(1)">确认已支付</el-button>
        </span>
      </el-dialog>
      <el-dialog
        @open="openDialog"
        class="dialogBox"
        title="账单确权"
        :close-on-click-modal="false"
        :visible.sync="billDialog"
        :destroy-on-close="true">
        <div>
          <billForm :item="currentPayRow" ref="billForm" :mobile="userInfo.mobile" />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="billDialog = false">取 消</el-button>
          <el-button type="primary" :loading="dialogButton" @click="submitBillForm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>

import dictionaryService from "@/service/common/dictionaryService.js";

import downloadFile from "@/util/downloadFile.js";

import batchPay from "./batchPay";

import payForm from "./payForm";

import billForm from "./billForm";

export default {
  data() {
    return {
      billDialog: false,
      funArr: [],
      loading: true,
      dialogButton: false,
      queryForm: {
        initiatorName: '',
        acceptorName: '',
        thirdParty: '',
        status: null,
        date: null,
        paymentTimeStart: null,
        paymentTimeEnd: null,
        pageNo: 1,
        pageSize: 10,
        dictionaryCode: process.env.VUE_APP_ENUM_PAYMENT_PLAN_STATUS,
      },
      stateCode: process.env.VUE_APP_ENUM_PAYMENT_PLAN_STATUS,
      stateArr: [],
      total: 0,
      // 数集合
      list: [],
      batchPayDialog: false,
      selectedRow: [],
      userInfo: {},
      // 应付帐会话框
      billPayableDialog: false,
      billPayableDialogName: '应付帐支付',
      payName: '应付帐支付',
      offlinePayName: '线下支付',
      paymentAmount: 0,
      noPaymentAmount: 0,
      currentPayRow: {},
      currentPaymentAmount: 0,
      isBatchOfflinePay: true,
    };
  },
  watch:{
    'queryForm.date'(newVal){
      if(newVal){
        if(newVal.length == 0){
          this.queryForm.paymentTimeStart = null;
          this.queryForm.paymentTimeEnd = null;
        }else if(newVal.length == 1){
          this.queryForm.paymentTimeStart = newVal[0];
          this.queryForm.paymentTimeEnd = null;
        }else{
          this.queryForm.paymentTimeStart = newVal[0];
          this.queryForm.paymentTimeEnd = newVal[1];
        }
      }else{
          this.queryForm.paymentTimeStart = null;
          this.queryForm.paymentTimeEnd = null;
      }
    }
  },
  mounted() {
    this.menuPermission();
    this.loadState();
    this.query();
    this.loadUserInfo();
  },
  methods: {
    query(){
      this.queryForm.pageNo = 1;
      this.loadList();
    },
    async menuPermission() {
      let name = "应付帐查询";
      let params = {
        id: this.$getCookie("sessionId"),
        menuName: name
      }
      let response = await this.api.platformApi.menuPermission(params);
      console.log(name + "页面功能列表start---")
      console.log(response.data.data)
      console.log(name + "页面功能列表end---")
      this.funArr = response.data.data
      console.log(this.funArr)
    },
    async loadList() {
      this.dialogButton = false;
      this.loading = true;
      await this.api.paymentPlanApi.getProofAndPaymentPlan(this.queryForm).then(res => {
        let data = res.data;
        console.log(data);
        if(data.code != 200){
          this.$message.error(data.msg);
          this.list = [];
          this.total = 0;
        }else{
          this.list = data.data.records;
          this.total = data.data.total;
          this.selectedRow = [];
        }
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      })
    },
    // 加载用户信息
    async loadUserInfo(){
      await this.api.platformApi.getUserInfo({}).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.userInfo = data.data;
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
    },
    // 
    billPayable(row){
      this.billPayableDialogName = this.payName;
      this.currentPaymentAmount = 0;
      this.openPay(row);
    },
    offlineBillPayable(row){
      this.billPayableDialogName = this.offlinePayName;
      this.openPay(row);
      this.currentPaymentAmount = this.noPaymentAmount;
    },
    openPay(row){
      this.currentPayRow = {...row}
      this.paymentAmount = Number(row.amount) / 100;
      let num = this.paymentAmount - (Number(row.paidAmount) / 100);
      this.noPaymentAmount =  num >= 0 ? num : 0;
      this.billPayableDialog = true;
    },
    submitPay(type){
      this.$refs.pay.submitForm((valid) => {
        if(valid){
          this.sendPay(type);
        }
      })
    },
    openBillDialog(row){
      this.currentPayRow = {...row}
      this.billDialog = true;
    },
    async sendPay(type){
      console.log(this.$refs.pay.formData);
      let formData = this.$refs.pay.formData;
      let param = {
        payServicer: formData.channelType,
        amount: formData.currentPaymentAmount * 100,
        paymentPlanId: this.currentPayRow.id,
        mobile: formData.mobile,
        verificationCode: formData.verificationCode
      }
      if(type === 0){
        await this.api.paymentPlanApi.onlinePayPaymentPlan(param).then(res => {
          let data = res.data;
          if(data.code == 200){
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.billPayableDialog = false;
            this.query();
          }else{
            this.$message.error(data.msg);
          }
        });
      }else if(type === 1){
        await this.api.paymentPlanApi.offlinePayPaymentPlan(param).then(res => {
          let data = res.data;
          if(data.code == 200){
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.billPayableDialog = false;
            this.query();
          }else{
            this.$message.error(data.msg);
          }
        });
      }
    },
    submitBatchPay(type){
      this.$refs.batchPay.submitForm((valid) => {
        if(valid){
          this.sendBatchPay(type);
        }
      })
    },
    async sendBatchPay(type){
      console.log(this.$refs.batchPay.formData);
      let formData = this.$refs.batchPay.formData;
      let arr = new Array();
      for (const key in this.selectedRow) {
        arr.push({
          amount: Number(this.selectedRow[key].valueNonPayment) * 100,
          paymentPlanId: this.selectedRow[key].id
        });
      }
      let param = {
        payServicer: formData.channelType,
        payParams: arr,
        mobile: formData.mobile,
        verificationCode: formData.verificationCode
      }
      if(type === 0){
        await this.api.paymentPlanApi.batchOnlinePayPaymentPlan(param).then(res => {
          let data = res.data;
          if(data.code == 200){
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.batchPayDialog = false;
            this.query();
          }else{
            this.$message.error(data.msg);
          }
        });
      }else if(type === 1){
        await this.api.paymentPlanApi.batchOfflinePayPaymentPlan(param).then(res => {
          let data = res.data;
          if(data.code == 200){
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.batchPayDialog = false
            this.query();
          }else{
            this.$message.error(data.msg);
          }
        });
      }
    },
    // 加载状态字典数据
    loadState() {
      dictionaryService
        .findDictionariesItemList(this.stateCode)
        .then((res) => {
          this.stateArr = res;
        }).catch((err) => {
      });
    },
    handleSelectionChange(val) {
      this.selectedRow = val;
      for (const key in this.selectedRow) {
        let item = this.selectedRow[key];
        let nonPayment = (item.amount - item.paidAmount)
        this.defineProperty(item,'nonPayment', nonPayment >=0 ? nonPayment : 0);
        this.defineProperty(item,'valueNonPayment',this.centChangeDollar(item.nonPayment));
      }
      // this.selectedRow.reverse();
    },
    defineProperty(obj,propertyName,value){
      this.$set(obj,propertyName,value);
    },
    openBatchPayDialog(){
      let length =  this.selectedRow.length;
      if(length <= 0){
        this.$message({
          message: '请选择应付帐',
          type: 'warning'
        });
        return;
      };
      let arr = this.selectedRow.filter(item => {
        return !item.isOfflinePay;
      })
      this.isBatchOfflinePay = arr.length > 0? true : false;
      this.batchPayDialog = true;
    },
    centChangeDollar(num){
        return num/100;
    },
    
    particulars(row){
      // paymentPlanParticulars
      row.statusLabel = this.stateArr[row.status].name;
      localStorage.setItem("paymentPlanParticulars",JSON.stringify(row));
       // JSON.parse(localStorage.getItem("paymentPlanParticulars"));
      this.$router.push({ name: "paymentPlanParticulars" });
    },
    // 确权
    // async authenticRight(row){
    //   // let amount = this.floatMultiply(Number(this.floatDivide(Number(row.invoiceOccupyRate), 1000000)),  Number(row.amount));
    //   await this.$confirm('您确定生成'+ row.equalingAmount/100 +'元额度吗?', '', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消'
    //   }).then(() => {
    //     console.log(row);
    //     let param = {
    //       riskId: row.acceptor,
    //       proof: row.blockId,
    //       amount: row.amount,
    //       expireDate: row.paymentTime,
    //     }
    //     console.log(param);
    //     this.addCreditProof(param);
    //   }).catch(() => {         
    //   });
    // },
    // 确权
    submitBillForm(){
      this.$refs.billForm.submitForm((valid) => {
        if(valid){console.log(this.$refs.billForm.logList)
          if(this.$refs.billForm.logList.length > 0){
            this.$message.warning('不可重复应付账确权!!!');
            return;
          }
          let row = this.$refs.billForm.formData;
          let param = {
            productId: row.productId,
            proofId:row.id,
            amount: this.accMul(row.equalingAmountValue , 100),
            expireDate: row.paymentTime,
            mobile: row.mobile,
            verificationCode: row.verificationCode
          }
          this.addCreditProof(param);
        }
      })
    },
    openDialog(){
      this.dialogButton = false;
    },
    async addCreditProof(param){
      this.dialogButton = true;
      this.api.paymentPlanApi.addCreditProof(param).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.sleep(3000);
          this.dialogButton = false;
          this.billDialog = false;
          this.query();
          this.$message({
              message: '确权成功',
              type: 'success'
            });
        }else{
          this.$message.error(data.msg);
          this.dialogButton = false;
        }
      })
      
    },
    sleep(numberMillis) {
        var now = new Date();
        var exitTime = now.getTime() + numberMillis;
        while (true) {
            now = new Date();
            if (now.getTime() > exitTime){
                return;
            }
    }
    },
    floatMultiply(arg1, arg2) {
        if(arg1 == null || arg2 == null){
            return null;
        }
        var n1,n2;
        var r1, r2; // 小数位数
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        n1 = Number(arg1.toString().replace(".", ""));
        n2 = Number(arg2.toString().replace(".", ""));
        return n1 * n2 / Math.pow(10, r1+r2);
    },
    floatDivide(arg1, arg2) {
        if(arg1 == null){
            return null;
        }
        if(arg2 == null || arg2 == 0){
            return null;
        }
        var n1,n2;
        var r1, r2; // 小数位数
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        n1 = Number(arg1.toString().replace(".", ""));
        n2 = Number(arg2.toString().replace(".", ""));
        return (n1 / n2) * Math.pow(10, r2 - r1);
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    pageChange(val) {
      this.queryForm.pageNo = val;
      this.loadList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    reset(){
      this.resetForm('queryForm');
      setTimeout(() => {
        this.query();
      }, 200);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 导出表格
    async exportTable(){
      console.log("导出表格");
      await this.api.paymentPlanApi.exportProofAndPaymentPlan(this.queryForm).then(res =>{
        console.log(res);
        downloadFile.exportExcel(res);
      })
      console.log("导出成功");
    }
  },
  components: {batchPay,payForm,billForm},
};
</script>
<style scoped>
.tabbox {
  text-align: left;
}
#table {
  /* padding: 20px 20px 0px 20px; */
  background-color: rgba(29, 144, 255, 0);
}
.el-dialog {
  text-align: left;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
}
.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}
.sx {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

.dialogBox {
  text-align: left;
}
/* ------------------- */
</style>

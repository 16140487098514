<template>
  <el-form label-width="150px" ref="formData" :model="formData" :rules="rules">
    <div style="padding-top: 40px"></div>
    <div>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12"
          ><div>
            <el-form-item label="户名:">
              <el-input disabled v-model="formData.name"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"><div class="main_class"></div></el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12"
          ><div>
            <el-form-item label="电子账户:">
              <el-input disabled v-model="formData.bankNum"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"><div class="main_class"></div></el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12"
          ><div>
            <el-form-item label="开户行:">
              <el-input disabled v-model="formData.bankName"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"><div class="main_class"></div></el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12"
          ><div>
            <el-form-item label="可用余额:">
              <el-input disabled v-model="formData.useamt"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"><div class="main_class"></div></el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12"
          ><div>
            <el-form-item label="付款金额:">
              <el-input disabled :value="formData.paymentAmount"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"
          ><div class="main_class">（币种：CNY；单位：元）</div></el-col
        >
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12"
          ><div>
            <el-form-item label="待支付金额:">
              <el-input disabled :value="formData.noPaymentAmount"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"
          ><div class="main_class">（币种：CNY；单位：元）</div></el-col
        >
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="16" :sm="9" :md="8"
          ><div>
            <el-form-item label="本次支付金额:" prop="currentPaymentAmount" >
              <el-input type="number"  @wheel.native.prevent="stopScroll($event)" v-model="formData.currentPaymentAmount"></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="8" :sm="7" :md="4"
          ><div class="main_buttom_class">
            <el-button type="primary" @click="formData.currentPaymentAmount = formData.noPaymentAmount">结清剩余余额</el-button>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="main_class">（币种：CNY；单位：元）</div></el-col
        >
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12">
          <div>
            <el-form-item label="支付通道:" prop="channelType">
              <el-select placeholder="请选择" v-model="formData.channelType">
                <el-option
                  v-for="channelType in channelArr"
                  :label="channelType.name"
                  :value="channelType.datavalue"
                  :key="channelType.datavaluex"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="5" />
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="12"
          ><div>
            <el-form-item label="手机号:" prop="mobile">
              <el-input disabled v-model="formData.mobile"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"></el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="12"
          ><div>
            <el-form-item label="验证码:" prop="verificationCode">
              <el-input v-model="formData.verificationCode"></el-input>
            </el-form-item></div
        ></el-col>
        <el-col :span="5"
          ><div>
            <div class="main_buttom_class">
              <div>
                <el-button type="primary" @click="sendSms">{{
                  timeStr
                }}</el-button>
              </div>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
  </el-form>
</template>

<script>
export default {
  name: "payForm",
  props: {
    mobile: {
      type: String,
      require: true,
    },
    paymentAmount: {
      type: Number,
      default: 0,
    },
    noPaymentAmount: {
      type: Number,
      default: 0,
    },
    currentPaymentAmount:{
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      channelCode: process.env.VUE_APP_ENUM_CHANNEL,
      channel: process.env.VUE_APP_PLATFORM_CHANNEL,
      channelArr: [],
      timeStr: "获取短信验证码",
      formData: {
        name: "",
        bankNum: "",
        bankName: "",
        useamt: "",
        invoiceSurplusAmount: "",
        channelType: "",
        mobile: this.mobile,
        verificationCode: "",
        currentPaymentAmount: this.currentPaymentAmount,
        paymentAmount: this.paymentAmount,
        noPaymentAmount: this.noPaymentAmount,
      },
      rules: {
        currentPaymentAmount: [
          { required: true, message: '金额必须填写', trigger: 'blur' }
        ],
        channelType: [
            { required: true, message: '支付通道必须', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '手机号必须', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '验证码必须', trigger: 'blur' }
        ]
      }
    };
  },
  async mounted() {
    await this.loadCompanyWallet();
    this.loadChannel().then((result) => (this.channelArr = result));
  },
  methods: {
    async loadCompanyWallet() {
      var param = {
        channel: this.channel,
      };
      await this.api.platformApi.getPayAccountInfo(param).then((res) => {
        let data = res.data;
        if (data.data == null || data.data == undefined) {
          this.formData.useamt = 0;
        } else {
          this.formData.useamt = data.data.findAccountBalance.data.useamt / 100;
        }
        this.formData.name = data.data.result.name;
        this.formData.bankNum = JSON.parse(
          data.data.result.walletComment
        ).rel_acct_no;
        this.formData.bankName = data.data.bankName;
      });
    },
    submitForm(func) {
      return this.$refs['formData'].validate(func);
    },
    async loadChannel() {
      let response = await this.api.platformApi.findDictionariesEnableItemList({
        code: this.channelCode,
      });
      return response.data.data;
    },
    //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      if (this.formData.mobile == null || this.formData.mobile == "") {
        this.$message({
          message: "没有手机号，无法获取验证码",
          type: "warning",
        });
        return;
      }
      var params = {
        mobile: this.formData.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    send_code() {
      this.show = true;
      var i = 60;
      var timer = setInterval(() => {
        i--;
        this.timeStr = i + "秒";
        if (i <= 0) {
          clearInterval(timer);
          this.timeStr = "重新获取";
        }
      }, 1000);
    },
    stopScroll(evt) {
        evt = evt || window.event;
        if (evt.preventDefault) {
          // Firefox
          evt.preventDefault();
          evt.stopPropagation();
        } else {
          // IE
          evt.cancelBubble = true;
          evt.returnValue = false;
        }
        return false;
      },
  },
  watch: {
    mobile(newV, oldV){
      this.formData.mobile = newV;
    },
    paymentAmount(newV, oldV){
      this.formData.paymentAmount = newV;
    },
    noPaymentAmount(newV, oldV){
      this.formData.noPaymentAmount = newV;
    },
    currentPaymentAmount(newV, oldV){
      this.formData.currentPaymentAmount = newV;
    },
  }
};
</script>

<style scoped>
.main_buttom_class {
  padding-left: 15px;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
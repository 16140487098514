<template>
    <el-form label-width="150px" ref="formData" :model="formData" :rules="rules">
      <div>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="应付账单编号:">
                <span>{{ formData.thirdParty }}</span>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="应收方:">
                <span>{{ formData.acceptorName }}</span>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="账单应付金额:">
                <span>{{  toFixed( accDiv(formData.amount , 100) )}} 元</span>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="当前可确权金额:">
                <span>{{ toFixed( accDiv(formData.equalingAmount , 100) ) }}元 </span>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="确权金额:" prop="equalingAmountValue">
                <el-input type="number" @wheel.native.prevent="stopScroll($event)" v-model="formData.equalingAmountValue"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="21" :md="17">
              <el-form-item label="确权产品:" prop="productId">
                <el-radio-group v-model="formData.productId">
                  <el-radio-button v-for="productInfo in productInfos" :key="productInfo.productId" :label="productInfo.productId">{{productInfo.name}}</el-radio-button>
                </el-radio-group>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="确定应付日期:" prop="paymentTime">
               <el-date-picker
                  v-model="formData.paymentTime"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="手机号码:" prop="mobile">
                <span>{{ mobile }}</span>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="12"
            ><div>
              <el-form-item label="验证码:" prop="verificationCode">
                <el-input v-model="formData.verificationCode"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"
            ><div>
              <div class="main_buttom_class">
                <div>
                  <el-button type="primary" @click="sendSms">{{
                    timeStr
                  }}</el-button>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <el-divider></el-divider>
      <div style="margin-bottom: 20px;color: #000;font-size: 18px;">确权记录</div>
      <div><el-table
      id="table"
      :data="logList"
      v-loading="loading"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="productName"
        label="确权产品"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="amount"
        label="确权金额"
        show-overflow-tooltip
        align="center"
      >
      <template slot-scope="scope">
        <span>{{ scope.row.amount!=null? (accDiv(scope.row.amount , 100)) : 0 }}</span>
      </template> 
      </el-table-column>
      <el-table-column
        prop="expireDate"
        label="确权日期"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="操作人"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="操作时间"
        show-overflow-tooltip
        align="center"
      />
      </el-table>
      </div>
    </el-form>
</template>

<script>
export default {
  name: 'billForm',
  props: {
    item: {
      type: Object,
      require: true,
    },
    mobile: {
      type: String,
      require: true,
    },
  },
  data(){
    return {
      loading: true,
      logList: [],
      timeStr: "获取短信验证码",
      formData: {
        verificationCode: '',
        equalingAmountValue: '',
        mobile: this.mobile,
        equalingAmount: '',
        productId:'',
      },
      productInfos:[
      ],
      rules: {
        equalingAmountValue: [
          { required: true, message: '确权金额必填', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (Number(value) <= 0) {
              return callback(new Error('确权金额不能小于0'));
            }
            if(this.accMul(Number(value) , 100) > this.formData.equalingAmount){
              return callback(new Error('确权金额不能大于可确权金额'));
            }
            return callback();
          }, trigger: 'blur'},
        ],
        paymentTime: [
            { required: true, message: '请选择确定应付日期', trigger: 'change'}
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        productId: [
          { required: true, message: '请选择确权产品', trigger: 'blur' }
        ],
      }
    }
  },
  created(){
    this.loadProductInfo();
  },
  methods: {
    // 当前确权金额
    async currentConfirmatoryAmount(){
      let param = {
        proofId: this.formData.id,
      }
      await this.api.paymentPlanApi.currentConfirmatoryAmount(param).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.formData.equalingAmount = data.data;
          this.formData.equalingAmountValue = this.accDiv(data.data , 100);
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
    },
    // 加载确权记录
    async loadConfirmationRecord(){
      // proofId: this.formData.id
      let param  = {
        proofId: this.formData.id,
      }
      await this.api.paymentPlanApi.getConfirmationRecord(param).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.logList = data.data;
          console.log(this.logList)
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
      this.loading = false;
    },
    toFixed(num){
      return num.toFixed(2);
    },
    submitForm(func) {
      return this.$refs['formData'].validate(func);
    },
     //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      if (this.mobile == null || this.mobile == "") {
        this.$message({
          message: "没有手机号，无法获取验证码",
          type: "warning",
        });
        return;
      }
      var params = {
        mobile: this.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    send_code() {
      this.show = true;
      var i = 60;
      var timer = setInterval(() => {
        i--;
        this.timeStr = i + "秒";
        if (i <= 0) {
          clearInterval(timer);
          this.timeStr = "重新获取";
        }
      }, 1000);
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    stopScroll(evt) {
        evt = evt || window.event;
        if (evt.preventDefault) {
          // Firefox
          evt.preventDefault();
          evt.stopPropagation();
        } else {
          // IE
          evt.cancelBubble = true;
          evt.returnValue = false;
        }
        return false;
    },
    loadProductInfo(){
      this.api.preLoanManagementApi.listProductByTenantId({}).then(response=>{
        // console.log('=====>',response)
        this.productInfos = response.data.data
        // console.log(this.productInfo)
      })
    },
  },
  watch: {
    item:{
      handler: function(newData,oldData){
        if(newData !== oldData){
          this.formData = {...newData}
          this.$set(this.formData,'equalingAmount', 0);
          this.$set(this.formData,'equalingAmountValue', 0);
          this.currentConfirmatoryAmount();
          this.loadConfirmationRecord();
          this.formData.mobile = this.mobile;
        }
      },
      deep:true,
      immediate: true
    },
    // 'formData.equalingAmount': {
    //   handler: function(newData,oldData){
    //     this.$set(this.formData,'equalingAmountValue',(newData / 100));
    //   },
    //   deep:true,
    //   immediate: true
    // }
  }
}
</script>

<style scoped>

.main_buttom_class {
  padding-left: 15px;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}
.el-radio-button{
  margin-right: 10px;
  margin-bottom: 8px;
}
</style>